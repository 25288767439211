/** @jsx jsx */

import { jsx, css } from "@emotion/core"
import Layout from "../../components/Layout"
import SEO from "../../components/seo"
import styled from "@emotion/styled"
import Logo from "../../assets/images/svg/logo-universal-gray.svg"
import ArrowLeft from "../../assets/images/svg/pix-tutorial/arrow-left.svg"
import Loading from "../../assets/images/svg/loadingbutton.svg"
import Whatsapp from "../../assets/images/svg/whatsApp.svg"
import { useSelector } from "react-redux"
import { jsPDF } from "jspdf"
import html2canvas from "html2canvas"
import moment from "moment"
import { isMobile, formatMoney } from "../../helpers"
import { useState } from "react"

const Container = styled.section`
  ${({ theme }) => css`
    padding: 112px 30px;
    color: #203760;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      text-align: start;
    }

    h2 {
      font-weight: bold;
      font-size: 36px;
      line-height: 40px;
      text-transform: none;

      p {
        font-size: 18px;
        color: #00a1ff;
        display: block;
      }
    }
  `}
`
export const Content = styled.div`
  display: flex;
  width: 446px;
  min-height: 402px;
  padding: 32px;
  margin-right: 220px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  line-height: 22px;

  border-radius: 12px;
  background: #e7efff;
  margin: 50px;
  margin-left: 100px;
  margin-right: 100px;

  h1 {
    margin-bottom: 8px;
  }

  span {
    color: var(--color-neutral-3, #919191);
    font-size: 16px;
    font-family: Poppins;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0.64px;
  }

  svg {
    width: 54px;
    height: 54px;
  }

  p {
    font-family: "Courier", monospace;
  }

  svg {
    width: 100px;
  }
`

export const Button = styled.button`
  display: flex;
  width: ${isMobile() ? 300 : 431}px;
  height: 50px;
  padding: 9px 39px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  gap: 10px;
  border: none;
  border-radius: 12px;
  background: var(--color-primary-1, #203760);
  cursor: pointer;

  color: #fff;
  text-align: center;
  font-size: 17px;
  font-family: Poppins;
  font-weight: 700;
`

export const Link = styled.a`
  display: flex;
  padding: 9px 39px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: ${isMobile() ? 300 : 431}px;
  border-radius: 12px;
  background: #00a1ff;

  p {
    color: #fff;
    text-align: center;
    font-size: 17px;
    font-family: Poppins;
    font-weight: 600;
  }

  svg {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
  }
`

const ConfirmationPagePix = ({ pageContext: translation }) => {
  const [isLoading, setIsLoading] = useState(false)
  const data = useSelector(state => state.app.pixData)

  function formatDateTime(isoDate) {
    const formattedDate = moment(isoDate).format("DD/MM/YYYY HH:mm:ss")
    return formattedDate
  }

  const generatePDF = async () => {
    setIsLoading(true)
    const content = document.getElementById("content")

    const canvas = await html2canvas(content)
    const imgData = canvas.toDataURL("image/png")
    const pdf = new jsPDF("p", "mm", "a4")
    const imgProps = pdf.getImageProperties(imgData)
    const pdfWidth = pdf.internal.pageSize.getWidth()
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight)
    pdf.save(`${formatDateTime(data.calendario.criacao)}.pdf`)
    setIsLoading(false)
  }

  function formatCPF(cpf) {
    if (!cpf) return
    cpf = cpf.toString()
    let formattedCPF = cpf.replace(
      /(\d{3})(\d{3})(\d{3})(\d{2})/,
      "$1.$2.$3-$4"
    )
    return formattedCPF
  }

  return (
    <Layout translation={translation}>
      <SEO lang={translation.currentLang} title={translation.pageName} />
      <div
        css={css`
          display: flex;
          align-items: center;
          width: "100%";
          justify-content: center;

          @media (max-width: 1158px) {
            flex-direction: column;
          }
        `}
      >
        <button
          css={css`
            align-items: center;
            justify-content: center;
            background-color: transparent;
            border: none;
            position: absolute;
            width: 50px;
            height: 50px;
            top: 20px;
            left: 20px;
          `}
          onClick={() => window.history.back()}
        >
          <ArrowLeft
            css={css`
              width: 20px;
              height: 20px;
            `}
          />
        </button>

        <Container>
          <div
            id="content"
            style={{
              width: "800px",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Content>
              <Logo />
              <div
                css={css`
                  div {
                    margin-bottom: 20px;
                  }
                `}
              >
                <div>
                  <p>PIX</p>
                </div>
                <p>VIA - WEB UNIVERSAL</p>
                <p>POS:{translation.donations}</p>
                <p>CPF:{formatCPF(data.devedor.cpf)}</p>
                <p>
                  <strong>{data.devedor.nome.toUpperCase()}</strong>
                </p>
                <p>AUT={data.denarioDonationId}</p>
                <p>
                  <strong>{formatDateTime(data.calendario.criacao)}</strong>
                </p>
                <div>
                  <p>
                    <strong>
                    {translation.value.toUpperCase()}:R${formatMoney(data.valor.original)}
                    </strong>
                  </p>
                </div>
                <div>
                <p>   <strong>IGREJA:{data.institutionName}   </strong></p>
                <p><strong>CAMPANHA/GRUPO:{data.campaignName}   </strong></p>
                </div>
                <p>{translation.onlineTransactionAuthorized}</p>
                <p>{translation.universalChurch}</p>
                <p>{data.chave}</p>
              </div>
            </Content>
          </div>
        </Container>

        <div
          css={css`
            margin-left: -150px;

            @media (max-width: 1158px) {
              margin-left: 0px;
              margin-bottom: 20px;
              align-items: center;
              justify-content: center;
              display: flex;
              flex-direction: column;
            }
          `}
        >
          <h2
            style={{
              marginBottom: "5px",
              fontSize: "26px",
              textAlign: "center",
            }}
          >
            {translation.donationSuccess}
          </h2>
          <p
            style={{
              marginBottom: "35px",
              fontSize: "17px",
              lineHeight: "24px",
              color: "#6E6E6E",
            }}
          >
            {translation.thanksForDonation} <br />
            {translation.yourHelpIsValuable}
          </p>
          <Button
            onClick={() => {
              generatePDF()
            }}
          >
            {isLoading ? <Loading /> : translation.seeProof}
          </Button>
          <Link href="https://wa.me/551123923333">
            <Whatsapp />
            <p>{translation.sendProofViaWhatsApp}</p>
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default ConfirmationPagePix
